// action - account reducer
import axios from "axios";
import configData from "./../config";

// export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_INITIALIZE = "ACCOUNT_INITIALIZE";
export const CHANGE_ADMIN_VIEW_TO_USER_VIEW = "CHANGE_ADMIN_VIEW_TO_USER_VIEW";
export const GO_BACK_TO_ADMIN_VIEW = "GO_BACK_TO_ADMIN";

export const UPDATE_SELECTED_METER_ID = "UPDATE_SELECTED_METER_ID"; //For Ems
export const UPDATE_SELECTED_METER_ID_1 = "UPDATE_SELECTED_METER_ID_1"; //For Wms
export const UPDATE_SELECTED_METER_ID_2 = "UPDATE_SELECTED_METER_ID_2"; // For DG
export const UPDATE_SELECTED_METER_ID_3 = "UPDATE_SELECTED_METER_ID_3"; // For NBS
export const UPDATE_SWITCH = "UPDATE_SWITCH";
export const UPDATE_HVAC_SWITCH = "UPDATE_HVAC_SWITCH";
export const UPDATE_TEMP_SWITCH = "UPDATE_TEMP_SWITCH";
export const UPDATE_FMS_SWITCH = "UPDATE_FMS_SWITCH";

export const SET_MENU = "@customization/SET_MENU";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";

export const fetchMeterInfo = () => {
  return async (dispatch, getState) => {
    const { isLoading, data, lastCachedTime } = getState().meterData;

    if (isLoading) {
      return;
    }

    // Check if data is available in local cache
    if (data && lastCachedTime - lastCachedTime < 60000) {
      // Data is fresh, dispatch it
      dispatch({ type: "FETCH_SUCCESS", payload: data });
      return data;
    }

    dispatch({ type: "FETCH_START" });
    const token = getState().account.ems.token;
    const main_meter_id = getState().account.ems.selected_meter_id;
    try {
      const config = {
        params: {
          meter_id: main_meter_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        configData.API_SERVER + "ems/get_datatable",
        config
      );
      const fetchedData = await response.data;

      dispatch({ type: "FETCH_SUCCESS", payload: fetchedData });
      return fetchedData;
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const fetchMeterInfo1 = () => {
  return async (dispatch, getState) => {
    const { isLoading, data, lastCachedTime } = getState().meterData;

    if (isLoading) {
      return;
    }

    // Check if data is available in local cache
    if (data && lastCachedTime - lastCachedTime < 60000) {
      // Data is fresh, dispatch it
      dispatch({ type: "FETCH_SUCCESS", payload: data });
      return data;
    }

    dispatch({ type: "FETCH_START" });
    const token = getState().account.nbs.token;
    console.log(token)
    const main_meter_id = getState().account.nbs.selected_meter_id;
    console.log(main_meter_id)
    try {
      const config = {
        params: {
          meter_id: main_meter_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        configData.API_SERVER + "nbsafe/get_datatable",
        config
      );
      const fetchedData = await response.data;

      dispatch({ type: "FETCH_SUCCESS", payload: fetchedData });
      return fetchedData;
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error.message });
      throw error;
    }
  };
};

let fetchTimer = null; // Global variable to track the timer

export const startAutoFetchMeterData = () => {
  return (dispatch) => {
    const fetchMeterData = () => {
      dispatch(fetchMeterInfo());
      fetchTimer = setTimeout(fetchMeterData, 60000); // Schedule the next fetch
    };

    // Initial data fetch
    fetchMeterData();

    // Clean up the timer when the component unmounts or when needed
    return () => {
      if (fetchTimer) {
        clearTimeout(fetchTimer);
        fetchTimer = null; // Reset the timer to null
      }
    };
  };
};

export const stopAutoFetchMeterData = () => {
  // Function to stop the ongoing timer
  return () => {
    if (fetchTimer) {
      clearTimeout(fetchTimer);
      fetchTimer = null; // Reset the timer to null
      // console.log("Auto-fetching has been stopped.");
    }
  };
};

//NBSafe Start Stop
export const startAutoFetchMeterData1 = () => {
  return (dispatch) => {
    const fetchMeterData = () => {
      dispatch(fetchMeterInfo1());
      fetchTimer = setTimeout(fetchMeterData, 60000); // Schedule the next fetch
    };

    // Initial data fetch
    fetchMeterData();

    // Clean up the timer when the component unmounts or when needed
    return () => {
      if (fetchTimer) {
        clearTimeout(fetchTimer);
        fetchTimer = null; // Reset the timer to null
      }
    };
  };
};

export const stopAutoFetchMeterData1 = () => {
  // Function to stop the ongoing timer
  return () => {
    if (fetchTimer) {
      clearTimeout(fetchTimer);
      fetchTimer = null; // Reset the timer to null
      // console.log("Auto-fetching has been stopped.");
    }
  };
};

export const updateSelectedMeterId = (newSelectedMeterId) => {
  // console.log("updateSelectedMeterId EMS called with ID:", newSelectedMeterId);
  return {
    type: UPDATE_SELECTED_METER_ID,
    payload: newSelectedMeterId,
  };
};

export const updateSelectedMeterId1 = (newSelectedMeterId) => {
  // console.log(
  //   "updateSelectedMeterId1 WATER called with ID:",
  //   newSelectedMeterId
  // );
  return {
    type: UPDATE_SELECTED_METER_ID_1,
    payload: newSelectedMeterId,
  };
};

export const updateSelectedMeterId2 = (newSelectedMeterId) => {
  // console.log("updateSelectedMeterId2 DG called with ID:", newSelectedMeterId);
  return {
    type: UPDATE_SELECTED_METER_ID_2,
    payload: newSelectedMeterId,
  };
};

export const updateSelectedMeterId3 = (newSelectedMeterId) => {
  console.log("updateSelectedMeterId3 NBS called with ID:", newSelectedMeterId);
  return {
    type: UPDATE_SELECTED_METER_ID_3,
    payload: newSelectedMeterId,
  };
};

export const updateSwitchPage = (newSwitch) => {
  return {
    type: UPDATE_SWITCH,
    payload: newSwitch,
  };
};

export const updateHvacSwitchPage = (newSwitch) => {
  return {
    type: UPDATE_HVAC_SWITCH,
    payload: newSwitch,
  };
};

export const updateTempSwitchPage = (newSwitch) => {
  return {
    type: UPDATE_TEMP_SWITCH,
    payload: newSwitch,
  };
};

export const updateFmsAlertSwitchPage = (newSwitch) => {
  return {
    type: UPDATE_FMS_SWITCH,
    payload: newSwitch,
  };
};

export const logOut = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_METER_DATA" });
    dispatch({ type: "GHG_DATA" });
    dispatch({ type: "MNV_DATA" });
    dispatch({ type: UPDATE_SWITCH});
    dispatch({ type: UPDATE_HVAC_SWITCH,payload: "chiller" });
    dispatch({ type: UPDATE_TEMP_SWITCH,payload: "temp" });
    dispatch({ type: UPDATE_FMS_SWITCH,payload: "hydrant" });
    dispatch({ type: LOGOUT });
  };
};

export const goBackToAdminView = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_METER_DATA" });
    dispatch({ type: GO_BACK_TO_ADMIN_VIEW });
  };
};
