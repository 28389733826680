// project imports
import config from "../config";

// action - state management
import * as actionTypes from "./actions";

// Load initial state from localStorage, if available
const loadStateFromLocalStorage = () => {


  const savedState = localStorage.getItem("persist:customization");

  if (savedState) {
    return JSON.parse(savedState);
  }
  return {
    isOpen: [],
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    selected_switch: "multiMeter",
    selected_hvac_switch: "chiller",
    selected_temp_switch: "temp",
    selected_fms_switch : "panel",
  };
};

export const initialState = loadStateFromLocalStorage();

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      const id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.UPDATE_SWITCH:
      return {
        ...state,
        selected_switch: action.payload,
      };
    case actionTypes.UPDATE_HVAC_SWITCH:
      return {
        ...state,
        selected_hvac_switch: action.payload,
      };
      case actionTypes.UPDATE_TEMP_SWITCH:
        return {
          ...state,
          selected_temp_switch: action.payload,
        };
        case actionTypes.UPDATE_FMS_SWITCH:
          return {
            ...state,
            selected_fms_switch: action.payload,
          };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
      case actionTypes.LOGOUT:
        // localStorage.removeItem("persist:customization"); 
              // Retrieve existing state from localStorage
      const storedState = localStorage.getItem("persist:customization");
      let parsedState = storedState ? JSON.parse(storedState) : {};

      // Remove only selected_switch from the stored state
      delete parsedState.selected_switch;

      // Save the updated state back to localStorage
      localStorage.setItem("persist:customization", JSON.stringify(parsedState));
        return {
          ...state,
          selected_switch: "multiMeter", 
          // selected_hvac_switch: "chiller",
        };
    default:
      return state;
  }
};

export default customizationReducer;
