import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import { useMenuItems } from './../../../../menu-items';
import dashboardType from "../../../../store/dashboard_type";

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = ({type=0}) => {
    const menuItem = useMenuItems();
    var selectedMenuItems = menuItem.items;

   if(type===dashboardType.ems){
        selectedMenuItems = menuItem.items;
    } else if(type===dashboardType.wms){
        selectedMenuItems = menuItem.wms_items;
    }
   else if(type===dashboardType.dg){
       selectedMenuItems = menuItem.dg_items;
   }
   else if(type===dashboardType.bms){
       selectedMenuItems = menuItem.bms_items;
   }
   else if(type===dashboardType.temp){
       selectedMenuItems = menuItem.temp_items;
   }
   else if(type===dashboardType.wms){
       selectedMenuItems = menuItem.items;
   }
   else if(type===dashboardType.fms){
       selectedMenuItems = menuItem.fms_items;
   }
   else if(type===dashboardType.hvc){
    selectedMenuItems = menuItem.hvc_items;
   }
   else if(type===dashboardType.vib){
    selectedMenuItems = menuItem.vib_items;
   }
   else if(type===dashboardType.nbs){
       selectedMenuItems = menuItem.nbs_items;
   }
   else if(type===dashboardType.trans){
    selectedMenuItems = menuItem.trans_items;
   }
   else if(type===dashboardType.agr){
    selectedMenuItems = menuItem.agr_items;
   }
   else if(type===dashboardType.gas){
    selectedMenuItems = menuItem.gas_items;
   }
   else if(type===dashboardType.air){
    selectedMenuItems = menuItem.air_items;
   }
   else if(type===dashboardType.superAdmin){
       selectedMenuItems = menuItem.superAdmin_items;
   }
   else if(type===dashboardType.admin){
       selectedMenuItems = menuItem.admin_items;
   }
   else if(type===dashboardType.supportAdmin){
    selectedMenuItems = menuItem.support_items;
}

    const navItems = selectedMenuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
