// assets
import {useSelector} from "react-redux";


import {
    IconDashboard,
    IconDeviceAnalytics,
    IconActivity,
    IconMailbox,
    IconBellPlus,
    IconReport,
    IconUser,
    IconTemperaturePlus,
    IconBatteryAutomotive,
    IconRouter,
    IconDevices,
    IconDetails,
    IconGitCompare,
    IconCalculator,
    IconRoute,
    IconKey,
    IconHelp,
    IconRecharging,
    IconSettingsAutomation,
    IconPlug,
    IconLink,
} from "@tabler/icons";
// constant
const icons = {
    IconActivity: IconActivity,
    IconDashboard: IconDashboard,
    IconMailbox: IconMailbox,
    IconBellPlus: IconBellPlus,
    IconReport: IconReport,
    IconDeviceAnalytics: IconDeviceAnalytics,
    IconUser: IconUser,
    IconTemperaturePlus: IconTemperaturePlus,
    IconBatteryAutomotive: IconBatteryAutomotive,
    IconRouter: IconRouter,
    IconSettingsAutomation: IconSettingsAutomation,
    IconDevices: IconDevices,
    IconDetails: IconDetails,
    IconGitCompare: IconGitCompare,
    IconCalculator: IconCalculator,
    IconRoute: IconRoute,
    IconKey: IconKey,
    IconHelp: IconHelp,
    IconPlug: IconPlug,
    IconRecharging: IconRecharging,
    IconLink: IconLink,
};
//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//
export const useEmsDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/ems/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/ems/analysis",
            icon: icons["IconActivity"],
            breadcrumbs: false,
        },
        {
            id: "mnv",
            title: "M&V",
            type: "item",
            url: "/ems/mnv",
            icon: icons["IconGitCompare"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/ems/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "report_gen",
            title: "Reports",
            type: "item",
            url: "/ems/report_gen",
            icon: icons["IconReport"],
            breadcrumbs: false,
        },
        {
            id: "kpi",
            title: "Kpi",
            type: "item",
            url: "/ems/kpi",
            icon: icons["IconKey"],
            breadcrumbs: false,
        },
        {
            id: "ghg",
            title: "GHG",
            type: "item",
            url: "/ems/ghg",
            icon: icons["IconCalculator"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/ems/support",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "ems_dashboard",
        type: "group",
        children,
    };
};

export const useDgDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/dg/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/dg/analysis",
            icon: icons["IconActivity"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/dg/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/dg/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "dg_dashboard",
        type: "group",
        children,
    };
};

export const bms_dashboard = {
    id: "bms_dashboard",
    // title: "Dashboard",
    type: "group",
    children: [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/bms/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/bms/analysis",
            icon: icons["IconDeviceAnalytics"],
            breadcrumbs: false,
        },
        {
            id: "alarms",
            title: "Alerts",
            type: "item",
            url: "/bms/alarms",
            icon: icons["IconBellPlus"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/bms/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ],
};

export const useTempDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/temp/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/temp/analysis",
            icon: icons["IconDeviceAnalytics"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/temp/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "hooter_list",
            title: "Hooter Setup",
            type: "item",
            url: "/temp/hooter_list",
            icon: icons["IconMailbox"],
            breadcrumbs: false,
        },
        {
            id: "report",
            title: "Reports",
            type: "item",
            url: "/temp/report",
            icon: icons["IconReport"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/temp/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "temp_dashboard",
        type: "group",
        children,
    };
};

export const useIndoorDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/indoor/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/indoor/analysis",
            icon: icons["IconDeviceAnalytics"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/indoor/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/indoor/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "indoor_dashboard",
        type: "group",
        children,
    };
};

export const useOutdoorDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/outdoor/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/outdoor/analysis",
            icon: icons["IconDeviceAnalytics"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/outdoor/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/outdoor/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "outdoor_dashboard",
        type: "group",
        children,
    };
};

export const useFmsDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/fms/home",
            icon: icons["IconDashboard"],
            breadcrumbs: true,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/fms/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/fms/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "fms_dashboard",
        type: "group",
        children,
    };
};

export const hvc_dashboard = {
    id: "hvc_dashboard",
    type: "group",
    children: [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/hvc/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/hvc/analysis",
            icon: icons["IconDeviceAnalytics"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/hvc/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ],
};

export const vib_dashboard = {
    id: "vib_dashboard",
    type: "group",
    children: [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/vib/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/vib/analysis",
            icon: icons["IconDeviceAnalytics"],
            breadcrumbs: false,
        },
        // {
        //     id: "report",
        //     title: "Report Generation",
        //     type: "item",
        //     url: "/vib/report",
        //     icon: icons["IconReport"],
        //     breadcrumbs: false,
        // },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/vib/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ],
};

export const useNBSDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/nbs/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/nbs/alarm",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "metering",
            title: "Metering",
            type: "item",
            url: "/nbs/metering",
            icon: icons["IconLink"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/nbs/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "nbs_dashboard",
        type: "group",
        children,
    };
};

export const trans_dashboard = {
    id: "trans_dashboard",
    // title: "Dashboard",
    type: "group",
    children: [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/trans/home",
            icon: icons["IconDashboard"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/trans/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ],
}

export const useWmsDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/wms/home",
            icon: icons["IconDashboard"],
            breadcrumbs: true,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/wms/analysis",
            icon: icons["IconActivity"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/wms/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        {
            id: "report",
            title: "Reports",
            type: "item",
            url: "/wms/report",
            icon: icons["IconReport"],
            breadcrumbs: false,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/wms/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
        // {
        //   id: "alarms_list",
        //   title: "List of Alarms",
        //   type: "item",
        //   url: "/wms/alarms_list",
        //   icon: icons["IconMailbox"],
        //   breadcrumbs: false,
        // },
    ];

    return {
        id: "wms_dashboard",
        type: "group",
        children,
    };
};

export const useAGRDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/agr/home",
            icon: icons["IconDashboard"],
            breadcrumbs: true,
        },
        // {
        //     id: "analysis",
        //     title: "Analysis",
        //     type: "item",
        //     url: "/agr/analysis",
        //     icon: icons["IconActivity"],
        //     breadcrumbs: false,
        // },Smart Agriculture
        // ...(account.user?.alert_access ? [
        //     {
        //         id: "alarms",
        //         title: "Alerts",
        //         type: "item",
        //         url: "/agr/alarms",
        //         icon: icons["IconBellPlus"],
        //         breadcrumbs: false,
        //     }
        // ] : []),
        // {
        //     id: "report",
        //     title: "Reports",
        //     type: "item",
        //     url: "/agr/report",
        //     icon: icons["IconReport"],
        //     breadcrumbs: false,
        // },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/agr/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "agr_dashboard",
        type: "group",
        children,
    };
};

export const useGASDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/gas/home",
            icon: icons["IconDashboard"],
            breadcrumbs: true,
        },
        {
            id: "analysis",
            title: "Analysis",
            type: "item",
            url: "/gas/analysis",
            icon: icons["IconActivity"],
            breadcrumbs: false,
        },
        ...(account.user?.alert_access ? [
            {
                id: "alarms",
                title: "Alerts",
                type: "item",
                url: "/gas/alarms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            }
        ] : []),
        // {
        //     id: "report",
        //     title: "Reports",
        //     type: "item",
        //     url: "/agr/report",
        //     icon: icons["IconReport"],
        //     breadcrumbs: false,
        // },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/gas/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "gas_dashboard",
        type: "group",
        children,
    };
};

export const useAIRDashboard = () => {
    const account = useSelector((state) => state.account);
    const children = [
        {
            id: "home",
            title: "Dashboard",
            type: "item",
            url: "/air/home",
            icon: icons["IconDashboard"],
            breadcrumbs: true,
        },
        {
            id: "support",
            title: "Support",
            type: "item",
            url: "/air/help",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ];

    return {
        id: "air_dashboard",
        type: "group",
        children,
    };
};

const NBAuditIcon = () => (
    <img src="https://esa.nbsense.in/assets/assets/logo/nbaudit_logo_transparent.png" alt="NBAudit" style={{width: 26, height: 26}}/>
);

export const useSuperAdminDashboard = () => {
    const account = useSelector((state) => state.account);

    // Define the base children array
    let children = [];

    // If user._id is 174, only show these specific sections
    if (account.user?._id === 174) {
        children = [
            {
                id: "home",
                title: "User",
                type: "item",
                url: "/superAdmin/user",
                icon: icons["IconUser"],
                breadcrumbs: false,
            },
            {
                id: "nbaudit",
                title: "NBAudit",
                type: "item",
                url: "/superAdmin/nbaudit",
                icon: <NBAuditIcon />,
                breadcrumbs: false,
            },
            {
                id: "client",
                title: "Client View",
                type: "item",
                url: "/superAdmin/client",
                icon: icons["IconDetails"],
                breadcrumbs: false,
            },
            {
                id: "help",
                title: "Support",
                type: "item",
                url: "/superAdmin/help",
                icon: icons["IconHelp"],
                breadcrumbs: false,
            },
        ];
    } else {
        // If user is not 174, show the other sections as per default
        children = [
            {
                id: "home",
                title: "User",
                type: "item",
                url: "/superAdmin/user",
                icon: icons["IconUser"],
                breadcrumbs: false,
            },
            {
                id: "device",
                title: "Gateway",
                type: "item",
                url: "/superAdmin/device",
                icon: icons["IconDashboard"],
                breadcrumbs: false,
            },
            {
                id: "imei",
                title: "Imei",
                type: "item",
                url: "/superAdmin/imei",
                icon: icons["IconDevices"],
                breadcrumbs: false,
            },
            {
                id: "meter",
                title: "Meter",
                type: "item",
                url: "/superAdmin/meter",
                icon: icons["IconRouter"],
                breadcrumbs: false,
            },
            {
                id: "wms",
                title: "Water Config",
                type: "item",
                url: "/superAdmin/wms",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            },
            {
                id: "dg",
                title: "Diesel Config",
                type: "item",
                url: "/superAdmin/dg",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            },
            {
                id: "bms",
                title: "Bms Config",
                type: "item",
                url: "/superAdmin/bms",
                icon: icons["IconBatteryAutomotive"],
                breadcrumbs: false,
            },
            {
                id: "temp",
                title: "Temp Config",
                type: "item",
                url: "/superAdmin/temp",
                icon: icons["IconTemperaturePlus"],
                breadcrumbs: false,
            },
            {
                id: "hvac",
                title: "Hvac Config",
                type: "item",
                url: "/superAdmin/hvac",
                icon: icons["IconSettingsAutomation"],
                breadcrumbs: false,
            },
            {
                id: "hooter",
                title: "Hooter Config",
                type: "item",
                url: "/superAdmin/hooter",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            },
            {
                id: "access",
                title: "Meter Access",
                type: "item",
                url: "/superAdmin/access",
                icon: icons["IconPlug"],
                breadcrumbs: false,
            },
            {
                id: "site",
                title: "Site Status",
                type: "item",
                url: "/superAdmin/site",
                icon: icons["IconRoute"],
                breadcrumbs: false,
            },
            {
                id: "client",
                title: "Client View",
                type: "item",
                url: "/superAdmin/client",
                icon: icons["IconDetails"],
                breadcrumbs: false,
            },
            {
                id: "view",
                title: "View Status",
                type: "item",
                url: "/superAdmin/view",
                icon: icons["IconActivity"],
                breadcrumbs: false,
            },
            {
                id: "help",
                title: "Support",
                type: "item",
                url: "/superAdmin/help",
                icon: icons["IconHelp"],
                breadcrumbs: false,
            },
        ];
    }

    return {
        id: "superAdmin_dashboard",
        type: "group",
        children,
    };
};


export const useAdminDashboard = () => {
        const account = useSelector((state) => state.account);

        // Define the base children array
        const children = [
            {
                id: "home",
                title: "User",
                type: "item",
                url: "/admin/user",
                icon: icons["IconUser"],
                breadcrumbs: false,
            },
            {
                id: "meter",
                title: "Meter",
                type: "item",
                url: "/admin/meter",
                icon: icons["IconBellPlus"],
                breadcrumbs: false,
            },
            {
                id: "client",
                title: "Client View",
                type: "item",
                url: "/admin/client",
                icon: icons["IconDetails"],
                breadcrumbs: false,
            },
            {
                id: "help",
                title: "Support",
                type: "item",
                url: "/superAdmin/help",
                icon: icons["IconHelp"],
                breadcrumbs: false,
            },
        ];

        // NBAudit section only is visible to boss
        if ((account.user ? account.user._id : 0) === 174) {
            children.push(
                {
                    id: "site",
                    title: "Site Status",
                    type: "item",
                    url: "/admin/site",
                    icon: icons["IconRoute"],
                    breadcrumbs: false,
                },
                {
                    id: "nbaudit",
                    title: "NBAudit",
                    type: "item",
                    url: "/admin/nbaudit",
                    icon: <NBAuditIcon/>,
                    breadcrumbs: false,
                },
                {
                    id: "help",
                    title: "Support",
                    type: "item",
                    url: "/admin/help",
                    icon: icons["IconHelp"],
                    breadcrumbs: false,
                },)
        }

        return {
            id: "admin_dashboard",
            type: "group",
            children,
        };
    };


export const support_dashboard = {
    id: "support_dashboard",
    type: "group",
    children: [
        {
            id: "home",
            title: "Support",
            type: "item",
            url: "/supportAdmin/home",
            icon: icons["IconHelp"],
            breadcrumbs: false,
        },
    ],
};


