// action - state management
import {
  ACCOUNT_INITIALIZE,
  CHANGE_ADMIN_VIEW_TO_USER_VIEW,
  GO_BACK_TO_ADMIN_VIEW,
  LOGIN,
  LOGOUT,
  UPDATE_SELECTED_METER_ID,
  UPDATE_SELECTED_METER_ID_1,
  UPDATE_SELECTED_METER_ID_2,
  UPDATE_SELECTED_METER_ID_3,
} from "./actions";

import userRoles from "./userRoles";

export const initialState = {
  token: "",
  dashboard_logo: 0,
  isLoggedIn: false,
  isInitialized: false,
  user_role: null,
  user: null,
  user_type: null,
  dg: null,
  bms: null,
  temp: null,
  fms: null,
  hvc: null,
  vib: null,
  ems: null,
  wms: null,
  nbs: null,
  trans: null,
  agr: null,
  gas: null,
  air: null,
  admin: null,
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_METER_ID:
      return {
        ...state,
        ems: {
          ...state.ems,
          selected_meter_id: action.payload,
        },
      };

    case UPDATE_SELECTED_METER_ID_1:
      return {
        ...state,
        wms: {
          ...state.wms,
          selected_meter_id: action.payload,
        },
      };

    case UPDATE_SELECTED_METER_ID_2:
      return {
        ...state,
        dg: {
          ...state.dg,
          selected_meter_id: action.payload,
        },
      };

      case UPDATE_SELECTED_METER_ID_3:
        console.log("UPDATE_SELECTED_METER_ID_2 action triggered");
        console.log("Previous state:", state.nbs);
        console.log("Previous selected_meter_id:", state.nbs?.selected_meter_id);
        return {
          ...state,
          nbs: {
            ...state.nbs,
            selected_meter_id: action.payload,
          },
        };

    // This Used to store account data while logging
    case ACCOUNT_INITIALIZE: {
      const {
        isLoggedIn,
        dashboard_logo,
        user,
        ems,
        dg,
        bms,
        temp,
        fms,
        hvc,
        vib,
        wms,
        nbs,
        trans,
        agr,
        gas,
        air,
        user_type,
        user_role,
        token,
      } = action.payload;
      let admin = null;
      if (
        user_role === userRoles.admin ||
        user_role === userRoles.superAdmin ||
        user_role === userRoles.systemAdmin ||
        user_role === userRoles.masterViewer
      ) {
        admin = {
          token,
          user,
          ems,
          dg,
          bms,
          temp,
          fms,
          hvc,
          vib,
          wms,
          nbs,
          trans,
          agr,
          gas,
          air,
          user_type,
          user_role,
        };
      }
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        admin: admin,
        token,
        user,
        ems,
        dg,
        bms,
        temp,
        fms,
        hvc,
        vib,
        wms,
        nbs,
        trans,
        agr,
        gas,
        air,
        user_type,
        user_role,
        dashboard_logo,
      };
    }

    // This method will update the admin view to user view
    case CHANGE_ADMIN_VIEW_TO_USER_VIEW: {
      const {
        isLoggedIn,
        user,
        ems,
        dg,
        bms,
        temp,
        fms,
        hvc,
        vib,
        wms,
        nbs,
        trans,
        agr,
        gas,
        air,
        user_type,
        user_role,
        token,
      } = action.payload;
      // let admin = null;
      // console.log(" im ADMIN_ACCOUNT_USER_CHANGE ");
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        // admin: admin, in this we don't update admin beacuse we need it
        token,
        user,
        ems,
        dg,
        bms,
        temp,
        fms,
        hvc,
        vib,
        wms,
        nbs,
        trans,
        agr,
        gas,
        air,
        user_type,
        user_role,
      };
    }

    // this will go back too admin view
    case GO_BACK_TO_ADMIN_VIEW: {
      return {
        ...state,
        ...state.admin,
        ems: null,
        dg: null,
        bms: null,
        temp: null,
        fms: null,
        hvc: null,
        vib: null,
        wms: null,
        nbs: null,
        trans: null,
        agr: null,
        gas: null,
        air: null,
      };
    }
    // case LOGIN: {
    //     console.log(" im now at LOGIN");
    //     const {user, ems, dg, bms, temp, fms, wms, nbs, user_type, user_role} = action.payload;
    //     let admin = null;
    //     if (user_type === userRoles.admin) {
    //         console.log(" log in it's under admin thing")
    //         admin = {
    //             user,
    //             ems,
    //             dg,
    //             bms,
    //             temp,
    //             fms,
    //             wms,
    //             nbs,
    //             user_type,
    //             user_role
    //         }
    //         console.log(admin);
    //     }
    //     return {
    //         ...state,
    //         isLoggedIn: true,
    //         user,
    //         ems,
    //         dg,
    //         bms,
    //         temp,
    //         fms,
    //         wms,
    //         nbs,
    //         user_type,
    //         user_role
    //     };
    // }
    case LOGOUT: {
      localStorage.removeItem("is_logged_in");
      return {
        ...state,
        isLoggedIn: false,
        admin: null,
        token: "",
        user: null,
        ems: null,
        dg: null,
        bms: null,
        temp: null,
        fms: null,
        hvc: null,
        vib: null,
        wms: null,
        nbs: null,
        trans: null,
        agr: null,
        gas: null,
        air: null,
        user_type: null,
        user_role: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
