import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reducer import
import customizationReducer from "./customizationReducer";
import accountReducer from "./accountReducer";

import { meterDataReducer } from "./meterDataReducer";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
  meterData: meterDataReducer,
  account: persistReducer(
    {
      key: "1.36",
      storage,
      keyPrefix: "nbsense-", // keyprfix is key in which store cache in client machine if you change the key from here , already installed user need to relogin to save the cache agiain with new key
    },
    accountReducer
  ),
  customization: customizationReducer,
});

export default reducer;
