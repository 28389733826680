import {
  bms_dashboard,
  useTempDashboard,
  // ems_dashboard,
  useEmsDashboard,
  useDgDashboard,
  useFmsDashboard,
  hvc_dashboard,
  vib_dashboard,
  useWmsDashboard,
  // nbs_dashboard,
  useNBSDashboard,
  trans_dashboard,
  useAGRDashboard,
  // superAdmin_dashboard,
  support_dashboard,
  useAdminDashboard,
  useSuperAdminDashboard,
  useGASDashboard,
  useIndoorDashboard,
  useOutdoorDashboard,
  useAIRDashboard,
} from "./ems_dashboard";

// Custom hook to generate menu items
export const useMenuItems = () => {

  const superDashboard = useSuperAdminDashboard();
  const adminDashboard = useAdminDashboard();
  const emsDashboard = useEmsDashboard();
  const wmsDashboard = useWmsDashboard();
  const tempDashboard = useTempDashboard();
  const indoorDashboard = useIndoorDashboard();
  const outdoorDashboard = useOutdoorDashboard();
  const dgDashboard = useDgDashboard();
  const nbsDashboard = useNBSDashboard();
  const agrDashboard = useAGRDashboard();
  const gasDashboard = useGASDashboard();
  const airDashboard = useAIRDashboard();
  const fmsDashboard = useFmsDashboard();

  return {
    items: [emsDashboard],
    dg_items: [dgDashboard],
    bms_items: [bms_dashboard],
    wms_items: [wmsDashboard],
    temp_items: [tempDashboard],
    indoor_items: [indoorDashboard],
    outdoor_items: [outdoorDashboard],
    fms_items: [fmsDashboard],
    hvc_items: [hvc_dashboard],
    vib_items: [vib_dashboard],
    // nbs_items: [nbs_dashboard],
    nbs_items: [nbsDashboard],
    trans_items: [trans_dashboard],
    agr_items: [agrDashboard],
    gas_items: [gasDashboard],
    air_items: [airDashboard],
    // superAdmin_items: [superAdmin_dashboard],
    superAdmin_items:[superDashboard],
    admin_items: [adminDashboard],
    support_items: [support_dashboard],
  };
};
