import Papa from "papaparse";

const downloadToCsv = (
  data = null,
  fileName = "data",
  tableHeader = [
    "Device_time",
    "Voltage_R",
    "Voltage_Y",
    "Voltage_B",
    "Average_Voltage",
    "Voltage_R_Y",
    "Voltage_R_B",
    "Voltage_Y_B",
    "Average_Voltage_LL",
    "Current_R",
    "Current_Y",
    "Current_B",
    "Average_Current",
    "Total_Active_Power",
    "Total_Apparent_Power",
    "Total_Reactive_Power",
    "Average_Power_Factor",
  ]
) => {
  if (data != null && data.length > 0) {
    // Add the table header
    // Replace with your actual header names
    const csvData = Papa.unparse({
      fields: tableHeader,
      data: data,
    });

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName + ".csv");
    link.click();
  } else {
    console.log("Cannot download CSV FILE CSV DATA is null");
  }
};

export { downloadToCsv };
