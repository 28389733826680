import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Drawer, useMediaQuery } from "@material-ui/core";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import MenuCard from "./MenuCard";
import { drawerWidth } from "./../../../store/constant";

//Company Logo
import nbLogo from "../../../assets/images/comapnyLogo/NLogo.svg";
// style constant
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 10,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    // background: theme.palette.background.default,
    background: "rgb(237, 231, 246,0.9)",
    borderRight: "1px solid #5a2198",
    color: "#000",
    // borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "51px",
    },
  },
  ScrollHeight: {
    height: "calc(100vh - 88px)",
    paddingLeft: "0px",
    paddingRight: "0px",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  boxContainer: {
    display: "flex",
    padding: "0px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, drawerToggle, window, type }) => {
  const account = useSelector((state) => state.account);
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  let logoSource;
  let logoStyle = {
    width: 72,
    height: 40,
  };

  // Determine which logo to display and its specific style
  if (account.dashboard_logo === 1) {
    logoSource = nbLogo;
    logoStyle.width = "100%";
    logoStyle.height = 35;
  } else if (account.dashboard_logo === 2) {
    logoSource = nbLogo;
    logoStyle.width = "100%";
    logoStyle.height = 35;
  } else if (account.dashboard_logo === 3) {
    logoSource = nbLogo;
    logoStyle.width = "100%";
    logoStyle.height = 35;
  } else if (account.dashboard_logo === 4) {
    logoSource = nbLogo;
    logoStyle.width = "100%";
    logoStyle.height = 35;
  } else {
    logoSource = "";
  }

  const drawer = (
    <React.Fragment>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <div className={classes.boxContainer}>
          <LogoSection />
        </div>
      </Box>
      <BrowserView>
        <PerfectScrollbar component="div" className={classes.ScrollHeight}>
          <MenuList type={type} />
          {/*<MenuCard />*/}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 0 }}>
          <MenuList type={type} />
        </Box>
      </MobileView>
    </React.Fragment>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        transitionDuration={0}
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}

        {logoSource && (
          <img
            src={logoSource}
            style={logoStyle}
            rel="preload"
            fetchpriority="high"
            alt="NBSense"
          />
        )}
      </Drawer>
    </nav>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
