import React, { useEffect, useState } from "react";
import MainCard from "../../../../ui-component/cards/MainCard";
import {
  CardContent,
  Grid,
  Table,
  Input,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./../../../utilities/AlarmSwitch.css";
import { downloadToCsv } from "../../dg/downloadToCsv";
import moment from "moment"; //To format date month or year
import { useLocation } from "react-router-dom";
import configData from "../../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import downloadImage from "../../../../assets/images/download1.png";

const IndoorAlarmsList = () => {
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const [tableData, setTableData] = useState({ read: [], unread: [] });
  const [toggle, setToggle] = useState("read");
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState(
    Array(tableData.unread.length).fill("")
  );
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isTextareaValid, setIsTextareaValid] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);

  // Function to fetch and update table data from API
  const fetchTableData = async () => {
    try {
      const token = account.temp.temp.token;
      // console.log(configData.API_SERVER + "th_ms/alert_sent");
      const response3 = await axios.get(
        configData.API_SERVER + "aqi/indoor_alert_sent",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { read, unread } = response3.data;
      setTableData({ read, unread });
    } catch (error) {
      console.error("Error loading table data:", error);
    }
  };

  useEffect(() => {
    fetchTableData();
    setComment(comments[selectedRowIndex] || "");
  }, [comments, selectedRowIndex]);

  const getReadData = () => {
    const dataCopy = [...readDatatable.rows];

    if (sortColumn && sortType) {
      dataCopy.sort((a, b) => {
        if (sortColumn === "id") {
          return sortType === "asc"
            ? a[sortColumn] - b[sortColumn]
            : b[sortColumn] - a[sortColumn];
        } else {
          let x = a[sortColumn];
          let y = b[sortColumn];
          x = typeof x === "string" ? x.toLowerCase() : x;
          y = typeof y === "string" ? y.toLowerCase() : y;

          if (typeof x === "number") {
            return sortType === "asc" ? x - y : y - x;
          }

          if (typeof x === "object" && x instanceof Date) {
            return sortType === "asc"
              ? x.getTime() - y.getTime()
              : y.getTime() - x.getTime();
          }

          return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
        }
      });
    }

    // Apply search filter
    const filteredData = dataCopy.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return filteredData;
  };

  const getUnreadData = () => {
    const dataCopy = [...unreadDatatable.rows];

    if (sortColumn && sortType) {
      dataCopy.sort((a, b) => {
        if (sortColumn === "id") {
          return sortType === "asc"
            ? a[sortColumn] - b[sortColumn]
            : b[sortColumn] - a[sortColumn];
        } else {
          let x = a[sortColumn];
          let y = b[sortColumn];
          x = typeof x === "string" ? x.toLowerCase() : x;
          y = typeof y === "string" ? y.toLowerCase() : y;

          if (typeof x === "number") {
            return sortType === "asc" ? x - y : y - x;
          }

          if (typeof x === "object" && x instanceof Date) {
            return sortType === "asc"
              ? x.getTime() - y.getTime()
              : y.getTime() - x.getTime();
          }

          return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
        }
      });
    }

    // Apply search filter
    const filteredData = dataCopy.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return filteredData;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const columns = [
    {
      label: "ID",
      field: "id",
      sort: "asc",
      width: 50,
    },
    {
      label: "Meter",
      field: "meter_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Parameter",
      field: "parameter_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Actual Value",
      field: "actual_value",
      sort: "asc",
      width: 150,
    },
    {
      label: "Minimum Threshold",
      field: "minimum_threshold",
      sort: "asc",
      width: 150,
    },
    {
      label: "Maximum Threshold",
      field: "maximum_threshold",
      sort: "asc",
      width: 150,
    },
    {
      label: "Method Type",
      field: "method",
      sort: "asc",
      width: 150,
    },
    {
      label: "Device Time",
      field: "device_time",
      sort: "asc",
      width: 150,
    },
    {
      label: "Sent At",
      field: "sent_at",
      sort: "asc",
      width: 150,
    },
    {
      label: "Comment",
      field: "comment",
      sort: "asc",
      width: 150,
    },
    {
      label: "Options",
      field: "options",
      sort: "asc",
      width: 150,
    },
  ];

  const [isRead, setIsRead] = useState(false);
  const handleToggle = () => {
    setIsRead(!isRead);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const unreadTableData = tableData.unread.map((item, index) => {
    return {
      id: index + 1,
      meter_name: item.meter_name,
      parameter_name: item.parameter_name,
      actual_value: item.actual_value,
      minimum_threshold: item.minimum_threshold,
      maximum_threshold: item.maximum_threshold,
      method: item.send_mode,
      device_time: moment(item.dtime).format("DD MMM YYYY HH:mm"),
      sent_at: item.details,
      comment: (
        <div style={{ position: "relative" }}>
          <Input
            id={`comment-${item.id}`}
            label="Comment"
            value={comments[index]}
            onChange={(event) => handleCommentChange(event, index)}
            variant="outlined"
            margin="dense"
            disabled={selectedRowIndex !== null && selectedRowIndex !== index}
          />
          {selectedRowIndex !== index &&
            (!comments[index] || comments[index].length === 0) && (
              <p
                style={{
                  fontSize: "9px",
                  color: "red",
                  position: "absolute",
                  bottom: "25px",
                }}
              >
                Add text up to 5 char
              </p>
            )}
        </div>
      ),
      options: (
        <div>
          <Button
            size="xs"
            onClick={() => handleAckno(item.id, comments[index])}
            style={{
              fontSize: "10px",
              color:
                selectedRowIndex === index && isButtonEnabled
                  ? "white"
                  : "blue",
              backgroundColor:
                selectedRowIndex === index && isButtonEnabled
                  ? "#8bc34a"
                  : "transparent",
              border:
                selectedRowIndex === index && isButtonEnabled
                  ? "1px solid #8bc34a"
                  : "none",
            }}
            disabled={
              !isTextareaValid ||
              (selectedRowIndex !== null && selectedRowIndex !== index)
            }
          >
            Acknowledge
          </Button>
        </div>
      ),
    };
  });

  const unreadDatatable = {
    columns: columns,
    rows: unreadTableData,
  };

  const readTableData = tableData.read.map((item, index) => ({
    id: index + 1,
    meter_name: item.meter_name,
    parameter_name: item.parameter_name,
    actual_value: item.actual_value,
    minimum_threshold: item.minimum_threshold,
    maximum_threshold: item.maximum_threshold,
    method: item.send_mode,
    device_time: moment(item.dtime).format("DD MMM YYYY HH:mm"),
    sent_at: item.details,
    comment: item.comment,
  }));

  const readDatatable = {
    columns: [...columns.slice(0, 11)],
    rows: readTableData,
  };

  const handleAckno = async (ackno_id, comment) => {
    if (window.confirm("Are you sure you want to acknowledge this alert?")) {
      try {
        // Make the delete request
        const token = account.temp.temp.token;
        // const token = '38398f9d-7af6-4acf-aaa6-7ea5e4dcf44f';
        const response = await axios.get(
          `${configData.API_SERVER}aqi/indoor_acknowledge`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              id: ackno_id,
              comment: comment,
            },
          }
        );

        // Check the response for success or failure
        if (response.status === 200) {
          // console.log("Item Acknowledged:", ackno_id, comment);
          // Move the comment from the unread table to the read table
          const updatedUnread = [...tableData.unread];
          const updatedRead = [...tableData.read];
          const acknowledgedItem = updatedUnread.find(
            (item) => item.id === ackno_id
          );
          acknowledgedItem.comment = comment;
          updatedRead.push(acknowledgedItem);
          updatedUnread.splice(updatedUnread.indexOf(acknowledgedItem), 1);

          // Update the table data state
          setTableData({ read: updatedRead, unread: updatedUnread });

          // Clear the comment and selectedRowIndex state
          setComments((prevComments) => {
            const updatedComments = [...prevComments];
            updatedComments[selectedRowIndex] = ""; // Clear the comment for the activated row
            return updatedComments;
          });
          setSelectedRowIndex(null);
          fetchTableData(); // Fetch updated table data after acknowledgment
        }
      } catch (error) {
        console.error("Error acknowledging item:", error);
      }
    }
  };

  const handleCommentChange = (event, rowIndex) => {
    const updatedComments = [...comments];
    updatedComments[rowIndex] = event.target.value;
    setComments(updatedComments);
    setSelectedRowIndex(rowIndex);

    if (event.target.value.length >= 5) {
      setIsTextareaValid(true);
      setIsButtonEnabled(true);
    } else {
      setIsTextareaValid(false);
      setIsButtonEnabled(false);
    }

    // Clear the comment and selectedRowIndex state if the comment is empty
    if (event.target.value === "") {
      setComments((prevComments) => {
        const updatedComments = [...prevComments];
        updatedComments[rowIndex] = ""; // Clear the comment for the current row
        return updatedComments;
      });
      setSelectedRowIndex(null);
    }
  };

  const handleDownload = () => {
    let data;
    let tableHeader;
    let fileName;

    if (isRead) {
      data = readTableData.map((item) => Object.values(item).slice(0, 11));
      tableHeader = columns.slice(0, 11).map((column) => column.label);
      fileName = "Read Alerts for Indoor Quality";
    } else {
      data = unreadTableData.map((item) => Object.values(item).slice(0, -2));
      tableHeader = columns.slice(0, -2).map((column) => column.label);
      fileName = "Unread Alerts for Indoor Quality";
    }

    downloadToCsv(data, fileName, tableHeader);
  };

  useEffect(() => {
    setTotalRows(
      toggle === "read"
        ? readDatatable.rows.length
        : unreadDatatable.rows.length
    );
  }, [readDatatable.rows, unreadDatatable.rows, toggle]);

  const generateTitle = (title) => {
    return (
      <span>
        {title}{" "}
        <span style={{ marginLeft: "5px", fontSize: "10px", color: "#ff3800" }}>
          (Upto 100 alerts only)
        </span>
      </span>
    );
  };

  return (
    <MainCard
      content={false}
      title={
        isRead
          ? generateTitle("Indoor Read Alerts")
          : generateTitle("Indoor Unread Alerts")
      }
      secondary={
        <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
          <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={() => setIsRead(false)}
              variant={!isRead ? "contained" : "outlined"}
              color="primary"
              style={{ marginLeft: "10px", width: "100%" }}
            >
              Unread Alerts
            </Button>
            <Button
              onClick={() => setIsRead(true)}
              variant={isRead ? "contained" : "outlined"}
              color="primary"
              style={{ marginLeft: "10px", width: "100%" }}
            >
              Read Alerts
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Input
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginBottom: 10, marginLeft: "10px" }}
        />
        <button
          style={{
            width: 35,
            margin: "10px 15px 10px 10px",
            height: 30,
            backgroundColor: "#fff",
            borderColor: "#fff",
          }}
          data-toggle="tooltip"
          title="Download"
          onClick={handleDownload}
        >
          <img
            src={downloadImage}
            alt=""
            style={{ width: "25px", height: "25px" }}
          />
        </button>
      </Grid>

      <CardContent>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            <div className="table-responsive">
              {isRead ? (
                <>
                  {" "}
                  <TableContainer
                    component={Paper}
                    sx={{ height: "500px" }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "500",
                              fontFamily: "Red Hat Display",
                              color: "#292929",
                            }}
                          >
                            Id
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Meter Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Parameter Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Actual Value
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Minimum
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Maximum
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Method Type
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Device Time
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Sent At
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Comment
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getReadData().map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 600,
                                color: "#292929",
                              }}
                            >
                              {row.id}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.meter_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.parameter_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.actual_value}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.minimum_threshold}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.maximum_threshold}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.method}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.device_time}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.sent_at}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.comment}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <>
                  {" "}
                  <TableContainer
                    component={Paper}
                    sx={{ height: "500px" }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "500",
                              fontFamily: "Red Hat Display",
                              color: "#292929",
                            }}
                          >
                            Id
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Meter Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Parameter Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Actual Value
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Minimum
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Maximum
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Method Type
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Device Time
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Sent At
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Comment
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ddf0f6",
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                              color: "#000",
                              fontSize: "16px",
                            }}
                          >
                            Options
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getUnreadData().map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 600,
                                color: "#292929",
                              }}
                            >
                              {row.id}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.meter_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.parameter_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.actual_value}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.minimum_threshold}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.maximum_threshold}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.method}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.device_time}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.sent_at}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "Red Hat Display",
                                fontWeight: 500,
                                color: "#292929",
                              }}
                            >
                              {row.comment}
                            </TableCell>
                            <TableCell align="center">{row.options}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </MainCard>
  );
};

export default IndoorAlarmsList;
